import { Nav } from "./nav.js";
import { Hints } from "./hints.js";
import { Game } from "./game.js";

let App = {
  DOM: {
    window: () => $(window),
    entireBody: () => $('html,body'),
    body: () => $('body'),
    fillHeightSection: () => $("section.fill-height"),
    collapseElements: () => $(".collapse")
  },

  handleFillHeight: () => {
    if(App.DOM.fillHeightSection().length > 0) {
      App.fillHeight()
      $(window).resize(App.fillHeight)
      $(document).on("turbolinks:render", App.fillHeight)
      $(App.DOM.collapseElements()).on("shown.bs.collapse", App.fillHeight)
      onElementHeightChange(document.body, App.fillHeight)
    }
  },

  fillHeight: () => {
    App.DOM.fillHeightSection().css("height","auto")

    var windowHeight = App.DOM.window().outerHeight()
    var bodyHeight = App.DOM.body().outerHeight()
    var diff = windowHeight - bodyHeight
    if(diff > 0) {
      App.DOM.fillHeightSection().outerHeight(App.DOM.fillHeightSection().outerHeight() + diff)
    }
  }
}

let onElementHeightChange = (elm, callback) => {
	var lastHeight = elm.clientHeight, newHeight;
  var lastScrollY = window.pageYOffset, newScrollY;
	(function run(){
		newHeight = elm.clientHeight;
		if( lastHeight != newHeight )
			callback();
		lastHeight = newHeight;
    if (elm.onElementHeightChangeTimer)
      clearTimeout(elm.onElementHeightChangeTimer);
		elm.onElementHeightChangeTimer = setTimeout(run, 200);
	})();
}

$(document).ready(() => {
  Nav.setUpNavbar();
  Nav.handleSmoothScrollLinks();
  Nav.tmpDisableAnimateNavBar();
  App.handleFillHeight();
  Hints.handleFormSubmission();
  Hints.handleGetHints()
  Game.handleGame();
});
