import { gsap } from "gsap";
import Cookies from 'js-cookie'
import { Utils } from './utils';
import { Nav } from "./nav.js";
import { Hints } from "./hints.js";

export let Game = {
  DOM: {
    playSection: () => $("section.play"),

    newGameButton: () => $(".new-btn"),
    getHintButton: () => $(".hint-btn"),
    helpButton:    () => $(".help-btn"),
    helpMessage:   () => $(".help-message"),

    game: () => $(".game"),

    hintText:         () => $(".game .hint-text"),
    hintTextCollapse: () => $(".game .hint-text-collapse"),

    playLevelInfoButton:      () => $(".play-level-setting .info"),
    playLevelSettingButtons:  () => $(".play-level-setting button:not(.info)"),
    playLevelSettingButton:   (level) => $(`.play-level-setting button[data-level=${level}]`),
    playLevelEasyButton:      () => $(".play-level-setting #easy-level-setting"),
    playLevelMediumButton:    () => $(".play-level-setting #medium-level-setting"),
    playLevelHardButton:      () => $(".play-level-setting #hard-level-setting"),
    playLevelMonstrousButton: () => $(".play-level-setting #monstrous-level-setting"),

    cardModeSettings:        () => $(".card-mode-setting"),
    cardModeInfoButton:      () => $(".card-mode-setting .info"),
    cardModeSettingButtons:  () => $(".card-mode-setting button:not(.info)"),
    cardModeSettingButton:   (mode) => $(`.card-mode-setting button[data-mode=${mode}]`),
    cardModeClassicButton:  () => $(".card-mode-setting #classic-mode-setting"),
    cardModeFunOnesButton:      () => $(".card-mode-setting #fun-ones-mode-setting"),

    cardDifficultySettings:     () => $(".card-difficulty-setting"),
    cardDifficultyInfoButton:   () => $(".card-difficulty-setting .info"),
    cardDifficultyStarsWrapper: () => $(".card-difficulty-setting .star-icons"),
    cardDifficultyStarButtons:  () => $(".card-difficulty-setting .star-icon"),
    cardDifficultyStar1Button:  () => $(".card-difficulty-setting .star-icon-1"),
    cardDifficultyStar2Button:  () => $(".card-difficulty-setting .star-icon-2"),
    cardDifficultyStar3Button:  () => $(".card-difficulty-setting .star-icon-3"),

    addButton:         () => $(".controls button.add"),
    subtractButton:    () => $(".controls button.sub"),
    multiplyButton:    () => $(".controls button.mul"),
    divideButton:      () => $(".controls button.div"),
    operationButtons:  () => $(".controls .op"),
    selectedOperation: () => $(".controls .op.selected"),
    resetButton:       () => $(".controls button.reset"),

    hexBg:            () => $(".game #hex_bg"),
    cardDiffHitArea:  () => $(".game #card_diff_hit_area"),
    playLevelHitArea: () => $(".game #play_level_hit_area"),
    modeLogoHitArea:  () => $(".game #mode_logo_hit_area"),

    redSection:    () => $(".game #red_section"),
    yellowSection: () => $(".game #yellow_section"),
    pinkSection:   () => $(".game #pink_section"),
    tealSection:   () => $(".game #teal_section"),
    blueSection:   () => $(".game #blue_section"),

    cardSections:     () => $(".game .hex-section"),
    cardSection:      (color) => $(".game .hex-section." + color),
    sectionForNumber: (number) => Game.DOM.cardSection(number.attr("data-color")),

    redNumber:    () => $(".game #red_number"),
    yellowNumber: () => $(".game #yellow_number"),
    pinkNumber:   () => $(".game #pink_number"),
    tealNumber:   () => $(".game #teal_number"),
    blueNumber:   () => $(".game #blue_number"),

    number:           (color) => $(".game #" + color + "_number"),
    numberForSection: (section) => Game.DOM.number(section.attr("data-color")),
    sectionNumbers:   () => $(".game .section-number"),
    selectedNumber:   () => $(".game .section-number.selected"),
    unselectedUnusedNumbers: () => $(".game .section-number:not(.selected):not(.used)"),
    usedNumbers:      () => $(".game .section-number.used"),
    unusedNumbers:    () => $(".game .section-number:not(.used)"),

    playLevelLabels:         () => $(".game .play-level-label"),
    playLevelLabelEasy:      () => $(".game #easy_label"),
    playLevelLabelMedium:    () => $(".game #medium_label"),
    playLevelLabelHard:      () => $(".game #hard_label"),
    playLevelLabelMonstrous: () => $(".game #monstrous_label"),
    playLevelLabel:          (level) => $(`.game .play-level-label.${level}`),

    oneStar:   () => $(".game #one_star"),
    twoStar:   () => $(".game #two_star"),
    threeStar: () => $(".game #three_star"),
    stars:     (id) => {
                  if(id) return $(".game #" + id)
                  else return $(".game .star")
                },

    adsumudisAnswer: () => $(".game #adsumudis_answer"),
    answerCircle:    () => $(".game #circle"),

    winMonster: () => $(".game #win_monster"),
    winMessage: () => $(".win-message"),
    signUpMessage: () => $(".sign-up-message"),

    modeLogos: () => $(".game #logo-classic, .game #logo-fun_ones"),
    modeLogo:  (mode) => $(".game #logo-" + mode),

    ariaAlert:   () => $("#aria-alert")
  },

  PlayLevel: {
    EASY: "easy",
    MEDIUM: "medium",
    HARD: "hard",
    MONSTROUS: "monstrous"
  },

  CardDifficulty: {
    STAR1: "one_star",
    STAR2: "two_star",
    STAR3: "three_star"
  },

  Mode: {
    CLASSIC: "classic",
    FUN_ONES: "fun_ones"
  },

  PLAY_LEVEL: null,
  CARD_DIFFICULTY: null,
  MODE: null,

  cards: null,
  currentCard: null,
  interactionPaused: false,
  gameOver: false,
  showedUseMoreNumbersPopUp: false,
  keyedNumberTimeout: null,
  keyedNumber: null,

  FIRST_HINT_CLASS: "first-hint",
  NEXT_HINT_CLASS: "next-hint",
  SOLUTION_CLASS: "solution",
  RESET_HINTS_CLASS: "reset-hints",
  NEW_BTN_PULSE_CLASS: "pulse",

  SETTING_SELECTED_CLASS: "selected",
  SETTING_HOVER_CLASS: "hover",
  NUMBER_SELECTED_CLASS: "selected",
  NUMBER_UNDERLINE_CLASS: "underline",
  NUMBER_USED_CLASS: "used",
  NUMBER_LARGE_CLASS: "large",
  OPERATION_ACTIVE_CLASS: "active",
  OPERATION_SELECTED_CLASS: "selected",

  NUM_USED_NUMBERS_ATTR: "num-used-numbers",
  PLAY_LEVEL_DATA_ATTR: "data-level",
  CARD_MODE_DATA_ATTR: "data-mode",

  NUMBER_SELECTED_SCALE: 1.2,

  handleGame: () => {
    if(Game.DOM.playSection().length == 0) return;

    Game.currentCard = FIRST_CARD
    Game.cards = MORE_CARDS
    Hints.setCard(Game.currentCard)

    Game.PLAY_LEVEL = Game.PlayLevel.EASY
    Game.CARD_DIFFICULTY = Game.CardDifficulty.STAR1
    Game.MODE = Game.Mode.CLASSIC
    Game.DOM.modeLogos().hide()
    Game.DOM.modeLogo(Game.MODE).show()
    Hints.setPlayLevel(Game.PLAY_LEVEL)

    let touchEvent = Utils.isMobile() ? "touchend" : "click"
    let extendedTouchEvent = touchEvent + " keydown"

    Game.DOM.newGameButton().on(touchEvent, Game.newGameButtonClicked)
    Game.DOM.getHintButton().on(touchEvent, Game.getHintButtonClicked)
    Game.DOM.helpButton().on(touchEvent, Game.helpButtonClicked)

    if(HINT_MODE) {
      Game.DOM.helpButton().parent().hide()
      Game.DOM.getHintButton().parent().removeClass("text-center")
      Game.DOM.getHintButton().parent().addClass("offset-4 text-right")
    }

    Game.DOM.playLevelEasyButton().attr(Game.PLAY_LEVEL_DATA_ATTR, Game.PlayLevel.EASY)
    Game.DOM.playLevelMediumButton().attr(Game.PLAY_LEVEL_DATA_ATTR, Game.PlayLevel.MEDIUM)
    Game.DOM.playLevelHardButton().attr(Game.PLAY_LEVEL_DATA_ATTR, Game.PlayLevel.HARD)
    Game.DOM.playLevelMonstrousButton().attr(Game.PLAY_LEVEL_DATA_ATTR, Game.PlayLevel.MONSTROUS)
    Game.DOM.playLevelSettingButtons().on(touchEvent, Game.playLevelSettingButtonClicked)
    Game.DOM.playLevelInfoButton().on(touchEvent, Game.playLevelInfoButtonClicked)
    Game.setPlayLevelFromPref()

    if(HINT_MODE) {
      Game.DOM.cardDifficultySettings().hide()
      Game.DOM.cardModeSettings().hide()
    } else {
      Game.DOM.cardDifficultyInfoButton().on(touchEvent, Game.cardDifficultyInfoButtonClicked)

      Game.DOM.cardDifficultyStar1Button().on(touchEvent, Game.cardDifficultyStar1ButtonClicked)
      Game.DOM.cardDifficultyStar2Button().on(touchEvent, Game.cardDifficultyStar2ButtonClicked)
      Game.DOM.cardDifficultyStar3Button().on(touchEvent, Game.cardDifficultyStar3ButtonClicked)
      Game.setCardDifficultyFromPref()

      Game.DOM.cardDifficultyStar1Button().hover(Game.cardDifficultyStar1ButtonHovered)
      Game.DOM.cardDifficultyStar2Button().hover(Game.cardDifficultyStar2ButtonHovered)
      Game.DOM.cardDifficultyStar3Button().hover(Game.cardDifficultyStar3ButtonHovered)

      Game.DOM.cardModeClassicButton().attr(Game.CARD_MODE_DATA_ATTR, Game.Mode.CLASSIC)
      Game.DOM.cardModeFunOnesButton().attr(Game.CARD_MODE_DATA_ATTR, Game.Mode.FUN_ONES)
      Game.DOM.cardModeSettingButtons().on(touchEvent, Game.cardModeSettingButtonClicked)
      Game.DOM.cardModeInfoButton().on(touchEvent, Game.cardModeInfoButtonClicked)
      if(FORCE_FUN_ONES_MODE) {
        Game.setCardModePref(Game.Mode.FUN_ONES)
      }
      Game.setCardModeFromPref()
    }

    Game.DOM.addButton().on(touchEvent, Game.addButtonClicked)
    Game.DOM.subtractButton().on(touchEvent, Game.subtractButtonClicked)
    Game.DOM.multiplyButton().on(touchEvent, Game.multiplyButtonClicked)
    Game.DOM.divideButton().on(touchEvent, Game.divideButtonClicked)
    Game.DOM.resetButton().on(touchEvent, Game.resetButtonClicked)

    Game.DOM.cardSections().on(touchEvent, Game.sectionClicked)
    Game.DOM.sectionNumbers().on(extendedTouchEvent, Game.numberClicked)

    Game.DOM.playSection().on(touchEvent, Game.clickedAway)

    Game.DOM.adsumudisAnswer().on(touchEvent, Game.adsumudisAnswerClicked)
    Game.DOM.answerCircle().on(extendedTouchEvent, Game.adsumudisAnswerClicked)
    Game.DOM.cardDiffHitArea().on(touchEvent, Game.cardDifficultyHitAreaClicked)
    Game.DOM.stars().on(extendedTouchEvent, Game.cardDifficultyHitAreaClicked)
    Game.DOM.playLevelHitArea().on(touchEvent, Game.playLevelHitAreaClicked)
    Game.DOM.modeLogoHitArea().on(touchEvent, Game.modeLogoHitAreaClicked)
    Game.DOM.modeLogos().on(extendedTouchEvent, Game.modeLogoHitAreaClicked)

    Game.DOM.playLevelLabelEasy().addClass(Game.PlayLevel.EASY)
    Game.DOM.playLevelLabelMedium().addClass(Game.PlayLevel.MEDIUM)
    Game.DOM.playLevelLabelHard().addClass(Game.PlayLevel.HARD)
    Game.DOM.playLevelLabelMonstrous().addClass(Game.PlayLevel.MONSTROUS)
    Game.DOM.playLevelLabels().on(extendedTouchEvent, Game.playLevelHitAreaClicked)

    $(document).on("keypress keydown", Game.gameControlKeyPressed)

    if(HINT_MODE) {
      Game.displayCurrentCard()
      Game.reset()
    } else {
      Game.newGameButtonClicked()
    }
  },

  shouldNotAllowKeydownEvent: (e) => {
    return e.type == "keydown" && !(e.keyCode == 32 || e.keyCode == 13)
  },

  blurElementForEvent: (event, el) => {
    if(!event || (event.type != "keydown" && (event.originalEvent && event.originalEvent.detail != 0))) el.blur()
  },

  displayCurrentCard: () => {
    Game.DOM.sectionNumbers().removeClass(Game.NUMBER_UNDERLINE_CLASS)

    Game.setNumber(Game.DOM.redNumber(), Game.currentCard.n1)
    Game.setNumber(Game.DOM.yellowNumber(), Game.currentCard.n2)
    Game.setNumber(Game.DOM.pinkNumber(), Game.currentCard.n3)
    Game.setNumber(Game.DOM.tealNumber(), Game.currentCard.n4)
    Game.setNumber(Game.DOM.blueNumber(), Game.currentCard.n5)
    Game.setNumber(Game.DOM.adsumudisAnswer(), Game.currentCard.target)

    Game.DOM.stars().hide()
    Game.DOM.stars(Game.currentCard.difficulty).show()
    Game.DOM.stars().attr("tabindex", "-1")
    Game.DOM.stars(Game.currentCard.difficulty).removeAttr("tabindex")
  },

  setNumber: (number, value) => {
    number.html(value)

    if(number.hasClass(Game.NUMBER_SELECTED_CLASS)) {
      number.attr("aria-label", `${value} selected`)
    } else {
      number.attr("aria-label", value)
    }

    if(Game.numberNeedsUnderline(value)) {
      number.addClass(Game.NUMBER_UNDERLINE_CLASS)
    } else {
      number.removeClass(Game.NUMBER_UNDERLINE_CLASS)
    }

    if(value > 99) {
      number.addClass(Game.NUMBER_LARGE_CLASS)
      if(number.hasClass("section-number")) {
        number.attr("y","63")
      }
    } else {
      number.removeClass(Game.NUMBER_LARGE_CLASS)
      if(number.hasClass("section-number")) {
        number.attr("y","68")
      }
    }
  },

  numberNeedsUnderline: (number) => {
    return [6, 9, 16, 18, 19, 61, 66, 68, 69, 81, 86, 89, 91, 96, 98, 99, 106, 108, 109, 116, 118, 119, 161, 166, 168, 169, 186, 188, 189, 191, 196, 198, 199, 601, 606, 608, 609, 611, 616, 618, 619, 661, 666, 668, 669, 681, 686, 688, 689, 691, 696, 698, 699, 901, 906, 908, 909, 911, 916, 918, 919, 961, 966, 968, 969, 981, 986, 988, 989, 991, 996, 998, 999].indexOf(number) != -1
  },

  newGameButtonClicked: (event = null) => {
    Game.blurElementForEvent(event, Game.DOM.newGameButton())
    if(Game.interactionPaused && !Game.gameOver) return;

    if(HINT_MODE) {
      window.location = "/hints"
    } else {
      Game.currentCard = Game.cards[Game.MODE][Game.CARD_DIFFICULTY].shift()
      Hints.setCard(Game.currentCard)
      if(Game.currentCard != null) {
        Game.reset()
      }
      Game.fetchCard()
    }
  },

  fetchCard: () => {
    var difficulty = Game.CARD_DIFFICULTY
    var mode = Game.MODE
    $.get("/fetch_card", {difficulty: difficulty, version: mode}).done((data) => {
      Game.cards[mode][difficulty].push(data)
    })
  },

  getHintButtonClicked: (event) => {
    Game.blurElementForEvent(event, Game.DOM.getHintButton())
    if(Game.gameOver) return;

    var hint = Hints.nextHint()
    var title = Utils.capitalize(Game.PLAY_LEVEL) + " Mode Hints"

    if(Hints.isFirstHint()) {
      Game.DOM.getHintButton().removeClass(Game.FIRST_HINT_CLASS)
      Game.DOM.getHintButton().addClass(Game.NEXT_HINT_CLASS)
    } else if(Hints.isFinalHintNext()) {
      Game.DOM.getHintButton().removeClass(Game.NEXT_HINT_CLASS)
      Game.DOM.getHintButton().addClass(Game.SOLUTION_CLASS)
    } else if(Hints.isFinalHint() && hint != null) {
      Game.DOM.getHintButton().removeClass(Game.SOLUTION_CLASS)
      Game.DOM.getHintButton().addClass(Game.RESET_HINTS_CLASS)
    } else if(Hints.isFinalHint() && hint == null) {
      Game.resetHints()
      return
    }

    if(Hints.isFirstHint()) {
      Game.DOM.hintText().html("<u>" + title + "</u>")
    }
    
    Game.DOM.hintText().html(Game.DOM.hintText().html() + "<br/>" + hint)
    Game.DOM.hintTextCollapse().collapse("show")
  },

  rememberPlayLevelHint: () => {
    return "Remember that on " + Game.PLAY_LEVEL + " mode you need to use at least " + Game.numCardsNeededForPlayLevel(Game.PLAY_LEVEL) + " numbers to make Adsumudi's answer"
  },

  isHintNumberSolutionHint: (num) => {
    var isSolutionHint = false
    if( ((Game.isEasyMode() || Game.isMediumMode()) && num == 3) ||
        ((Game.isHardMode() || Game.isMonstrousMode()) && num == 4)) {
          isSolutionHint = true;
    }
    return isSolutionHint
  },



  resetHints: () => {
    Hints.resetHints()
    Game.DOM.getHintButton().addClass(Game.FIRST_HINT_CLASS)
    Game.DOM.getHintButton().removeClass(Game.NEXT_HINT_CLASS)
    Game.DOM.getHintButton().removeClass(Game.SOLUTION_CLASS)
    Game.DOM.getHintButton().removeClass(Game.RESET_HINTS_CLASS)
    Game.DOM.hintTextCollapse().collapse("hide")
  },

  resetOperationButtonsAria: () => {
    Game.DOM.addButton().attr("aria-label","addition")
    Game.DOM.subtractButton().attr("aria-label","subtraction")
    Game.DOM.multiplyButton().attr("aria-label","multiplication")
    Game.DOM.divideButton().attr("aria-label","division")
  },

  helpButtonClicked: (event) => {
    Game.blurElementForEvent(event, Game.DOM.helpButton())
    showModal("How To Play", Game.DOM.helpMessage().html())
  },

  playLevelInfoButtonClicked: (event) => {
    showModal("Play Level","When playing on <b>easy</b>, you can use <b>any two or more</b> of the five numbers around the outside of the card to make Adsumudi's answer in the middle. On <b>medium</b>, you have to use <b>three or more</b>. On <b>hard</b>, you have to use <b>any four or more</b>. And if you're playing on <b>monstrously hard</b>, you have to use <b>all five numbers</b>.")
    Game.blurElementForEvent(event, Game.DOM.playLevelInfoButton())
  },

  cardDifficultyInfoButtonClicked: (event) => {
    showModal("Card Difficulty","One, two, or three stars indicates a card's <b>general difficulty level</b>, where <b>1 star is the easiest</b> and <b>3 stars is the hardest</b>. Select the difficulty you would like to play with to get a new card of that difficulty.")
    Game.blurElementForEvent(event, Game.DOM.cardDifficultyInfoButton())
  },

  currentCardNumStars: () => {
    if(Game.currentCard.difficulty == Game.CardDifficulty.STAR1) {
      return 1
    } else if(Game.currentCard.difficulty == Game.CardDifficulty.STAR2) {
      return 2
    } else if(Game.currentCard.difficulty == Game.CardDifficulty.STAR3) {
      return 3
    }
  },

  playLevelSettingButtonClicked: (event) => {
    var button = $(event.target)
    if(button.length == 0) return
    var level = button.attr(Game.PLAY_LEVEL_DATA_ATTR)
    var didChange = Game.PLAY_LEVEL != level

    Game.PLAY_LEVEL = level
    Hints.setPlayLevel(level)
    Game.DOM.playLevelSettingButtons().removeClass(Game.SETTING_SELECTED_CLASS)
    button.addClass(Game.SETTING_SELECTED_CLASS)
    Game.blurElementForEvent(event, button)
    Game.setPlayLevelPref(level)
    Game.resetHints()

    Game.DOM.playLevelLabels().hide()
    if(!Game.gameOver) {
      Game.DOM.playLevelLabel(level).show()
      Game.DOM.playLevelLabels().attr("tabindex", "-1")
      Game.currentPlayLevelLabel().removeAttr("tabindex")
    }

    if(didChange && event.type) {
      var levelDesc = "can now use any <b>two or more</b>"
      if(Game.isMediumMode()) levelDesc = "now have to use <b>three or more</b>"
      else if(Game.isHardMode()) levelDesc = "now have to use <b>four or more</b>"
      else if(Game.isMonstrousMode()) levelDesc = "now have to use <b>all five</b>"
      showModal(`${Utils.capitalize(level)} Mode Selected`, `You ${levelDesc} of the numbers around the outside to make Adsumudi's answer in the middle.`)
    }

    if(didChange && Game.DOM.usedNumbers().length > 0) {
      Game.reset()
    }
  },

  currentPlayLevelLabel: () => {
    if(Game.isEasyMode()) {
      return Game.DOM.playLevelLabelEasy()
    } else if(Game.isMediumMode()) {
      return Game.DOM.playLevelLabelMedium()
    } else if(Game.isHardMode()) {
      return Game.DOM.playLevelLabelHard()
    } else if(Game.isMonstrousMode()) {
      return Game.DOM.playLevelLabelMonstrous()
    }
  },

  playLevelPref: () => {
    return Cookies.get("play-level-pref")
  },

  setPlayLevelPref: (level) => {
    Cookies.set("play-level-pref", level)
  },

  setPlayLevelFromPref: () => {
    var pref = Game.playLevelPref()
    if(pref) {
      Game.playLevelSettingButtonClicked({target: Game.DOM.playLevelSettingButton(pref)})
    }
  },

  cardModeSettingButtonClicked: (event) => {
    var button = $(event.target)
    if(button.length == 0) return
    var mode = button.attr(Game.CARD_MODE_DATA_ATTR)
    var didChange = Game.MODE != mode

    Game.MODE = mode
    Game.DOM.cardModeSettingButtons().removeClass(Game.SETTING_SELECTED_CLASS)
    button.addClass(Game.SETTING_SELECTED_CLASS)
    Game.blurElementForEvent(event, button)
    Game.setCardModePref(mode)
    Game.resetHints()

    Game.DOM.modeLogos().hide()
    Game.DOM.modeLogo(mode).show()
    
    Game.DOM.playSection().removeClass(`theme-${Game.Mode.CLASSIC}`)
    Game.DOM.playSection().removeClass(`theme-${Game.Mode.FUN_ONES}`)
    Game.DOM.playSection().addClass(`theme-${mode}`)

    if(didChange && event.type) {
      var levelDesc = ""
      var title = ""
      if(Game.isClassicMode()) {
        showModal(`Classic Mode Selected`, `You selected "Classic" mode, which means you may need any one or all of + - × ÷ to solve cards and numbers range up to 30. This is the standard way of playing Adsumudi.`)
      } else if(Game.isFunOnesMode()) {
        showModal(`Fun Ones Mode Selected`, `You selected "Fun Ones" mode, which means only + and - are needed to solve cards and numbers only go up to 12. You can still use × and ÷ if you want, but you don't have to. This makes for a fun, fast-paced challenge.`)
      }

      Game.newGameButtonClicked()
    }
  },

  cardModeInfoButtonClicked: (event) => {
    showModal("Card Type","\"Classic\" is the regular way of playing Adsumudi, where any one or all of + - × ÷ may be needed to solve cards and numbers range up to 30. \"Fun Ones\" means only + and - are needed and numbers only go up to 12, making for some fun, fast-paced mental math.")
    Game.blurElementForEvent(event, Game.DOM.cardModeInfoButton())
  },

  cardModePref: () => {
    return Cookies.get("card-mode-pref")
  },

  setCardModePref: (mode) => {
    Cookies.set("card-mode-pref", mode)
  },

  setCardModeFromPref: () => {
    var pref = Game.cardModePref()
    if(pref) {
      Game.cardModeSettingButtonClicked({target: Game.DOM.cardModeSettingButton(pref)})
    }
  },

  isMode: (mode) => {
    return Game.MODE == mode
  },

  isClassicMode: () => {
    return Game.isMode(Game.Mode.CLASSIC)
  },

  isFunOnesMode: () => {
    return Game.isMode(Game.Mode.FUN_ONES)
  },

  numCardsNeededForPlayLevel: () => {
    return {
      [Game.PlayLevel.EASY]: 2,
      [Game.PlayLevel.MEDIUM]: 3,
      [Game.PlayLevel.HARD]: 4,
      [Game.PlayLevel.MONSTROUS]: 5
    }[Game.PLAY_LEVEL]
  },

  isPlayLevel: (playLevel) => {
    return Game.PLAY_LEVEL == playLevel
  },

  isEasyMode: () => {
    return Game.isPlayLevel(Game.PlayLevel.EASY)
  },

  isMediumMode: () => {
    return Game.isPlayLevel(Game.PlayLevel.MEDIUM)
  },

  isHardMode: () => {
    return Game.isPlayLevel(Game.PlayLevel.HARD)
  },

  isMonstrousMode: () => {
    return Game.isPlayLevel(Game.PlayLevel.MONSTROUS)
  },


  cardDifficultyStar1ButtonHovered: () => {
    Game.DOM.cardDifficultyStarButtons().removeClass(Game.SETTING_HOVER_CLASS)
    Game.DOM.cardDifficultyStar1Button().addClass(Game.SETTING_HOVER_CLASS)
  },

  cardDifficultyStar2ButtonHovered: () => {
    Game.DOM.cardDifficultyStarButtons().removeClass(Game.SETTING_HOVER_CLASS)
    Game.DOM.cardDifficultyStar1Button().addClass(Game.SETTING_HOVER_CLASS)
    Game.DOM.cardDifficultyStar2Button().addClass(Game.SETTING_HOVER_CLASS)
  },

  cardDifficultyStar3ButtonHovered: () => {
    Game.DOM.cardDifficultyStarButtons().addClass(Game.SETTING_HOVER_CLASS)
  },

  cardDifficultyStarsMouseOut: () => {
    Game.DOM.cardDifficultyStarButtons().removeClass(Game.SETTING_HOVER_CLASS)
  },

  cardDifficultyStar1ButtonClicked: (event) => {
    if(Game.interactionPaused && !Game.gameOver) return;
    var didChange = Game.CARD_DIFFICULTY != Game.CardDifficulty.STAR1
    Game.CARD_DIFFICULTY = Game.CardDifficulty.STAR1
    Game.DOM.cardDifficultyStarButtons().removeClass(Game.SETTING_SELECTED_CLASS)
    Game.DOM.cardDifficultyStar1Button().addClass(Game.SETTING_SELECTED_CLASS)
    Game.blurElementForEvent(event, Game.DOM.cardDifficultyStarButtons())
    Game.setCardDifficultyPref(Game.CARD_DIFFICULTY)
    if(didChange) Game.newGameButtonClicked()
  },

  cardDifficultyStar2ButtonClicked: (event) => {
    if(Game.interactionPaused && !Game.gameOver) return;
    var didChange = Game.CARD_DIFFICULTY != Game.CardDifficulty.STAR2
    Game.CARD_DIFFICULTY = Game.CardDifficulty.STAR2
    Game.DOM.cardDifficultyStarButtons().removeClass(Game.SETTING_SELECTED_CLASS)
    Game.DOM.cardDifficultyStar1Button().addClass(Game.SETTING_SELECTED_CLASS)
    Game.DOM.cardDifficultyStar2Button().addClass(Game.SETTING_SELECTED_CLASS)
    Game.blurElementForEvent(event, Game.DOM.cardDifficultyStarButtons())
    Game.setCardDifficultyPref(Game.CARD_DIFFICULTY)
    if(didChange) Game.newGameButtonClicked()
  },

  cardDifficultyStar3ButtonClicked: (event) => {
    if(Game.interactionPaused && !Game.gameOver) return;
    var didChange = Game.CARD_DIFFICULTY != Game.CardDifficulty.STAR3
    Game.CARD_DIFFICULTY = Game.CardDifficulty.STAR3
    Game.DOM.cardDifficultyStarButtons().removeClass(Game.SETTING_SELECTED_CLASS)
    Game.DOM.cardDifficultyStar1Button().addClass(Game.SETTING_SELECTED_CLASS)
    Game.DOM.cardDifficultyStar2Button().addClass(Game.SETTING_SELECTED_CLASS)
    Game.DOM.cardDifficultyStar3Button().addClass(Game.SETTING_SELECTED_CLASS)
    Game.blurElementForEvent(event, Game.DOM.cardDifficultyStarButtons())
    Game.setCardDifficultyPref(Game.CARD_DIFFICULTY)
    if(didChange) Game.newGameButtonClicked()
  },

  cardDifficultyPref: () => {
    return Cookies.get("card-difficulty-pref")
  },

  setCardDifficultyPref: (diff) => {
    Cookies.set("card-difficulty-pref", diff)
  },

  setCardDifficultyFromPref: () => {
    var pref = Game.cardDifficultyPref()
    if(pref == Game.CardDifficulty.STAR3) {
      Game.cardDifficultyStar3ButtonClicked()
    } else if(pref == Game.CardDifficulty.STAR2) {
      Game.cardDifficultyStar2ButtonClicked()
    } else if(pref == Game.CardDifficulty.STAR1) {
      Game.cardDifficultyStar1ButtonClicked()
    }
  },

  hasSelectedNumber: () => {
    var result = false
    $.each(Game.DOM.sectionNumbers(), (i, number) => {
      if(Game.isNumberSelected(number)) {
        result = true
      }
    })
    return result
  },

  isNumberSelected: (number) => {
    return $(number).hasClass(Game.NUMBER_SELECTED_CLASS)
  },

  hasSelectedOperation: () => {
    var result = false
    $.each(Game.DOM.operationButtons(), (i, op) => {
      if(Game.isOperationSelected(op)) {
        result = true
      }
    })
    return result
  },

  isOperationSelected: (op) => {
    return $(op).hasClass(Game.OPERATION_SELECTED_CLASS)
  },

  isOperationActive: (op) => {
    return $(op).hasClass(Game.OPERATION_ACTIVE_CLASS)
  },

  resetKeyedNumber: () => {
    Game.keyedNumber = null
    if(Game.keyedNumberTimeout != null) {
      clearTimeout(Game.keyedNumberTimeout)
      Game.keyedNumberTimeout = null
    }
  },

  gameControlKeyPressed: (event) => {
    if(Game.interactionPaused && !Game.gameOver) return;

    var isKeyPress = event.type == "keypress"
    var isKeyDown = event.type == "keydown"
    var k = event.which

    if(isKeyPress) {
      if(k == 43) {
        Game.resetKeyedNumber()
        Game.addButtonClicked(event)
      }
      else if(k == 45) {
        Game.resetKeyedNumber()
        Game.subtractButtonClicked(event)
      }
      else if(k == 120 || k == 42) {
        Game.resetKeyedNumber()
        Game.multiplyButtonClicked(event)
      }
      else if(k == 47 || k == 92) {
        Game.resetKeyedNumber()
        Game.divideButtonClicked(event)
      }
      else if(k == 82 || k == 114) {
        Game.resetButtonClicked(event)
      }
      else if(k == 78 || k == 110) {
        Game.newGameButtonClicked(event)
      }
      else if(k == 72 || k == 104) {
        Game.getHintButtonClicked(event)
      }
      else if(k >= 48 && k <= 57) {
        if(Game.keyedNumberTimeout != null) {
          clearTimeout(Game.keyedNumberTimeout)
          Game.keyedNumberTimeout = null
        }

        var num = String.fromCharCode(k)
        Game.keyedNumber = (Game.keyedNumber == null) ? num : (Game.keyedNumber + num)
        Game.keyedNumberTimeout = setTimeout(() => {
          Game.keyedNumber = null
        }, 1000)

        var number = Game.DOM.unusedNumbers().filter((i, el) => {
          return $(el).html() == Game.keyedNumber
        }).first()

        var couldBeAnotherNumber = Game.DOM.unusedNumbers().filter((i, el) => {
          return $(el).html().indexOf(Game.keyedNumber) == 0 && $(el).html() != Game.keyedNumber
        }).length > 0

        if(number.length > 0) {
          if(Game.keyedNumberDisambiguateTimeout != null) {
            clearTimeout(Game.keyedNumberDisambiguateTimeout)
          }
          if(Game.hasSelectedNumber() && Game.hasSelectedOperation() && couldBeAnotherNumber) {
            Game.keyedNumberDisambiguateTimeout = setTimeout(() => {
              Game.numberSelected(number)
            }, 500)
          } else {
            Game.numberSelected(number)
          }
        }
      }
    }
    else if(isKeyDown) {
      if(k == 27 || k == 12)
        Game.clickedAway(event)
    }
  },

  commitKeyedNumber: (number) => {
    if(number.length > 0) {
      Game.numberSelected(number)
    }
  },

  addButtonClicked: (event) => {
    event.stopPropagation()
    Game.blurElementForEvent(event, Game.DOM.addButton())
    if(Game.gameOver) return;

    if(Game.isOperationActive(Game.DOM.addButton())) {
      Game.DOM.operationButtons().removeClass(Game.OPERATION_SELECTED_CLASS)
      Game.DOM.addButton().addClass(Game.OPERATION_SELECTED_CLASS)
      alert
      Game.DOM.addButton().attr("aria-label","addition selected")
      Game.ariaSay("addition selected")
    } else {
      showModal("Select a number first!", "To add two numbers, first click on a number to select it, then click this button, then click the other number.")
    }
  },

  isAddSelected: () => {
    return Game.hasSelectedOperation() && Game.DOM.selectedOperation().hasClass("add")
  },

  subtractButtonClicked: (event) => {
    event.stopPropagation()
    Game.blurElementForEvent(event, Game.DOM.subtractButton())
    if(Game.gameOver) return;

    if(Game.isOperationActive(Game.DOM.subtractButton())) {
      Game.DOM.operationButtons().removeClass(Game.OPERATION_SELECTED_CLASS)
      Game.DOM.subtractButton().addClass(Game.OPERATION_SELECTED_CLASS)
      Game.resetOperationButtonsAria()
      Game.DOM.subtractButton().attr("aria-label","subtraction selected")
      Game.ariaSay("subtraction selected")
    } else {
      showModal("Select a number first!", "To subtract two numbers, first click the number you want to subtract from to select it, then click this button, then click the number you want to subtract with.")
    }
  },

  isSubtractSelected: () => {
    return Game.hasSelectedOperation() && Game.DOM.selectedOperation().hasClass("sub")
  },

  multiplyButtonClicked: (event) => {
    event.stopPropagation()
    Game.blurElementForEvent(event, Game.DOM.multiplyButton())
    if(Game.gameOver) return;

    if(Game.isOperationActive(Game.DOM.multiplyButton())) {
      Game.DOM.operationButtons().removeClass(Game.OPERATION_SELECTED_CLASS)
      Game.DOM.multiplyButton().addClass(Game.OPERATION_SELECTED_CLASS)
      Game.resetOperationButtonsAria()
      Game.DOM.multiplyButton().attr("aria-label","multiplication selected")
      Game.ariaSay("multiplication selected")
    } else {
      showModal("Select a number first!", "To multiply two numbers, first click a number, then click this button, then click the other number.")
    }
  },

  isMultiplySelected: () => {
    return Game.hasSelectedOperation() && Game.DOM.selectedOperation().hasClass("mul")
  },

  divideButtonClicked: (event) => {
    Game.blurElementForEvent(event, Game.DOM.divideButton())
    event.stopPropagation()
    if(Game.gameOver) return;

    if(Game.isOperationActive(Game.DOM.divideButton())) {
      Game.DOM.operationButtons().removeClass(Game.OPERATION_SELECTED_CLASS)
      Game.DOM.divideButton().addClass(Game.OPERATION_SELECTED_CLASS)
      Game.resetOperationButtonsAria()
      Game.DOM.divideButton().attr("aria-label","division selected")
      Game.ariaSay("division selected")
    } else {
      showModal("Select a number first!", "To divide two numbers, first click the number you want to divide from, then click this button, then click the number you want to divide with.")
    }
  },

  isDivideSelected: () => {
    return Game.hasSelectedOperation() && Game.DOM.selectedOperation().hasClass("div")
  },

  resetButtonClicked: (event) => {
    event.stopPropagation()
    Game.blurElementForEvent(event, Game.DOM.resetButton())
    if(Game.interactionPaused && !Game.gameOver) return;
    Game.reset()
  },

  reset: () => {
    Game.gameOver = false
    Game.interactionPaused = false
    Game.showedUseMoreNumbersPopUp = false
    Game.resetKeyedNumber()

    // Game.interactionPaused = true
    Game.resetHints()
    Game.DOM.playSection().removeClass("win")
    Game.DOM.winMonster().hide()
    Game.DOM.newGameButton().removeClass(Game.NEW_BTN_PULSE_CLASS)
    Game.displayCurrentCard()
    Game.DOM.sectionNumbers().removeClass(Game.NUMBER_SELECTED_CLASS)
    Game.DOM.sectionNumbers().removeClass(Game.NUMBER_USED_CLASS)
    Game.DOM.sectionNumbers().attr(Game.NUM_USED_NUMBERS_ATTR, "1")
    Game.DOM.sectionNumbers().removeAttr("tabindex")
    Game.DOM.cardSections().removeClass(Game.NUMBER_USED_CLASS)

    Game.DOM.playLevelLabels().attr("tabindex", "-1")
    Game.currentPlayLevelLabel().removeAttr("tabindex")
    Game.DOM.playLevelLabels().hide()
    Game.currentPlayLevelLabel().show()

    gsap.killTweensOf(Game.DOM.sectionNumbers())
    gsap.to(Game.DOM.sectionNumbers(), {
      duration: 0,
      scale: 1,
      opacity: 1,
      rotate: 0,
      transformOrigin: "50% 50%",
      onComplete: () => {
        Game.bounce(Game.DOM.sectionNumbers(), 1, () => {
          // Game.interactionPaused = false
        })
      }
    });
    Game.DOM.operationButtons().removeClass(Game.OPERATION_ACTIVE_CLASS)
    Game.DOM.operationButtons().removeClass(Game.OPERATION_SELECTED_CLASS)
    Game.resetOperationButtonsAria()
  },

  sectionClicked: (event) => {
    event.stopPropagation()
    if(Game.interactionPaused) return;

    var section = $(event.target)
    Game.numberSelected(Game.DOM.numberForSection(section))
  },

  numberClicked: (event) => {
    if(Game.shouldNotAllowKeydownEvent(event)) return;

    var number = $(event.target)
    Game.blurElementForEvent(event, number)
    event.stopPropagation()

    if(Game.interactionPaused) return;

    Game.numberSelected(number)
  },

  clickedAway: () => {
    if(Game.interactionPaused) return;
    if(Game.DOM.selectedNumber().length > 0) {
      Game.deselectNumber(Game.DOM.selectedNumber())
    }
  },

  adsumudisAnswerClicked: (event) => {
    if(Game.interactionPaused) return;
    if(Game.shouldNotAllowKeydownEvent(event)) return;

    var desc = "For this card, <b>Adsumudi's answer is " + Game.currentCard.target + "</b>. It's the target number you're trying to make by combining the 5 other numbers with "
    if(Game.isClassicMode()) {
      desc += "addition, subtraction, multiplication, and division."
    } else { 
      desc += "addition and subtraction (and multiplication and division if you want)."
    } 
    if(HINT_MODE) {
      desc += " Try it yourself by clicking on one of the numbers and then a math symbol, or click the 'Hint' button at top right if you're stuck."
    } else {
      desc += " Get started by clicking on one of the 5 numbers around the outside of the card."
    }

    showModal("Adsumudi's Answer", desc)
  },

  cardDifficultyHitAreaClicked: (event) => {
    if(Game.interactionPaused) return;
    if(Game.shouldNotAllowKeydownEvent(event)) return;

    var formattedDifficulty = Utils.pluralize(Game.currentCard.difficulty.split("_").join(" "), Game.currentCardNumStars())
    var formattedCapitalizedDifficulty = $.map(formattedDifficulty.split(" "), (w, i) => {
      return Utils.capitalize(w)
    }).join(" ")

    var cardDifficultyDesc = ""
    if(Game.currentCard.difficulty == Game.CardDifficulty.STAR1) {
      cardDifficultyDesc = "it's the <b>easiest</b> type of card"
    } else if(Game.currentCard.difficulty == Game.CardDifficulty.STAR2) {
      cardDifficultyDesc = "it's of <b>medium difficulty</b>"
    } else if(Game.currentCard.difficulty == Game.CardDifficulty.STAR3) {
      cardDifficultyDesc = "it's the <b>hardest</b> type of card"
    }

    var settingsDesc = ""
    if(!HINT_MODE) {
      settingsDesc = "You can <b>change this setting</b> at the bottom of the page."
    }

    var explanation = `This card has an overall rating of <b>${formattedDifficulty}</b>, meaning that generally, ${cardDifficultyDesc}. ${settingsDesc}`

    showModal(formattedCapitalizedDifficulty,  explanation)
  },

  playLevelHitAreaClicked: (event) => {
    if(Game.interactionPaused) return;
    if(Game.shouldNotAllowKeydownEvent(event)) return;

    var playLevelDesc = Game.numCardsNeededForPlayLevel(Game.PLAY_LEVEL) + " or more numbers"
    if(Game.isMonstrousMode()) {
      playLevelDesc = "all 5 numbers"
    }

    var settingsDesc = "You can <b>change this setting</b> at the bottom of the page."

    var explanation = `You're currently playing on <b>${Game.PLAY_LEVEL}</b>, which means you have to use <b>${playLevelDesc}</b> to make Adsumudi's answer. ${settingsDesc}`

    var title = Utils.capitalize(Game.PLAY_LEVEL) + " Level"

    showModal(title,  explanation)
  },

  modeLogoHitAreaClicked: (event) => {
    if(Game.interactionPaused) return;
    if(Game.shouldNotAllowKeydownEvent(event)) return;

    var title = Game.isClassicMode() ? "Classic Adsumudi" : "Adsumudi Fun Ones"
    var explanation = `You're currently playing on <b>${title} mode</b>, which means `
    if(Game.isClassicMode())
      explanation += "you may need any one or all of + - × ÷ to solve cards and numbers range up to 30."
    else
      explanation += "only + and - are needed to solve cards and numbers only go up to 12. You can still use × and ÷ if you want, but you don't have to."

    explanation += " You can <b>change this setting</b> at the bottom of the page."

    showModal(title, explanation)
  },

  selectNumber: (number) => {
    number.addClass(Game.NUMBER_SELECTED_CLASS)
    number.attr("aria-label", `${number.html()}, selected`)
    Game.ariaSay(`${number.html()}, selected`)

    // Game.interactionPaused = true
    gsap.killTweensOf(number)
    gsap.to(number, {
      duration: 0.25,
      scale: Game.NUMBER_SELECTED_SCALE,
      transformOrigin: "50% 50%",
      onComplete: () => {
        // Game.interactionPaused = false
      }
    });

    Game.DOM.operationButtons().addClass(Game.OPERATION_ACTIVE_CLASS)
  },

  deselectNumber: (number) => {
    number.removeClass(Game.NUMBER_SELECTED_CLASS)
    number.attr("aria-label", number.html())
    Game.ariaSay(`${number.html()}, deselected`)

    // Game.interactionPaused = true
    gsap.killTweensOf(number)
    gsap.to(number, {
      duration: 0.25,
      scale: 1,
      transformOrigin: "50% 50%",
      onComplete: () => {
        // Game.interactionPaused = false
      }
    });
    Game.DOM.operationButtons().removeClass(Game.OPERATION_ACTIVE_CLASS)
    Game.DOM.operationButtons().removeClass(Game.OPERATION_SELECTED_CLASS)
    Game.resetOperationButtonsAria()
  },

  numberSelected: (number) => {
    if(number.html() == "") return

    if(!Game.hasSelectedOperation()) {
      if(Game.hasSelectedNumber()) {
        if(Game.isNumberSelected(number)) {
          Game.deselectNumber(number)
        } else {
          Game.deselectNumber(Game.DOM.selectedNumber())
          Game.selectNumber(number)

        }
      } else {
        Game.selectNumber(number)
      }
    } else {
      if(Game.hasSelectedNumber()) {
        if(!Game.isNumberSelected(number)) {
          Game.performOperation(number)
        }
      }
    }
  },

  performOperation: (secondNumber) => {
    var selectedNumber = Game.DOM.selectedNumber()
    var operand1 = parseInt(selectedNumber.html())
    var operand2 = parseInt($(secondNumber).html())
    var result = null;
    if(Game.isAddSelected()) {
      result = operand1 + operand2
    } else if(Game.isSubtractSelected()) {
      result = operand1 - operand2
    } else if(Game.isMultiplySelected()) {
      result = operand1 * operand2
    } else if(Game.isDivideSelected()) {
      result = operand1 / operand2
    }

    if(Game.validateResult(result)) {
      Game.DOM.operationButtons().removeClass(Game.OPERATION_SELECTED_CLASS)
      Game.resetOperationButtonsAria()
      secondNumber.addClass(Game.NUMBER_USED_CLASS)
      secondNumber.attr("tabindex","-1")
      Game.DOM.sectionForNumber(secondNumber).addClass(Game.NUMBER_USED_CLASS)
      gsap.killTweensOf(secondNumber)
      gsap.to(secondNumber, {
        duration: 0.5,
        scale: 0.3,
        rotate: 360,
        opacity: 0,
        transformOrigin: "50% 50%"
      });

      Game.setNumber(selectedNumber, result)
      Game.ariaSay(`${result} created`)

      var numUsedNumbers = parseInt(secondNumber.attr(Game.NUM_USED_NUMBERS_ATTR)) +
                           parseInt(selectedNumber.attr(Game.NUM_USED_NUMBERS_ATTR))
      selectedNumber.attr(Game.NUM_USED_NUMBERS_ATTR, numUsedNumbers)

      // don't let the user continue clicking things if the game is over!
      let {hasWinningMatch, hasMatch, numUsedInMatch} = Game.checkForWin()
      if(hasWinningMatch || (hasMatch && numUsedInMatch > 1 && !Game.showedUseMoreNumbersPopUp)) {
        Game.interactionPaused = true
      }

      Game.bounce(selectedNumber, Game.NUMBER_SELECTED_SCALE, () => {
        if(hasWinningMatch) {
          Game.win()
        } else if(hasMatch && numUsedInMatch > 1 && !Game.showedUseMoreNumbersPopUp) {
          var atLeastAll = Game.isMonstrousMode() ? "all" : "at least"
          showModal("Use more numbers!","Good job making that " + Game.currentCard.target + ", but you <b>only used " + numUsedInMatch + " numbers</b> to make it. Since you're playing on <b>" + Game.PLAY_LEVEL + " mode</b>, you <b>have to use " + atLeastAll + " " + Game.numCardsNeededForPlayLevel(Game.PLAY_LEVEL) + " numbers</b> to make Adsumudi's answer. Keep going or hit 'Reset' to try again. You can also change your play level towards the bottom of the page.")
          Game.interactionPaused = false
          Game.showedUseMoreNumbersPopUp = true
        }
      })
    }
  },

  validateResult: (result) => {
    if(result == Infinity) {
      showModal("That's not possible!", "Nice try, but it's not possible to divide a number by zero. See if you can try something else.")
      return false
    } else if(result < 0) {
      showModal("No need for negatives!", "Sure you <i>could</i> do that, but it would make " + result + " and there's no need for negative numbers in this game. See if you can try something else.")
      return false
    } else if (result > 999) {
      showModal("That's too big!", "Sure you <i>could</i> do that, but it would make " + result + " and there's no need for really large numbers in this game. See if you can try something else.")
      return false
    } else if (result != Math.round(result)) {
      showModal("No need for decimals!", "Sure you <i>could</i> do that, but it would make " + result.toFixed(5).replace(/0+$/g,"") + " and there's no need for decimals or fractions in this game. See if you can try something else.")
      return false
    }
    return true
  },

  bounce: (elements, backTo = 1, onComplete = () => {}) => {
    // Game.interactionPaused = true

    gsap.killTweensOf(elements)
    gsap.to(elements, {
      duration: 0.25,
      scale: backTo * 1.3,
      transformOrigin: "50% 50%",
      ease: "power4.out",
      onComplete: () => {
        gsap.to(elements, {
          duration: 1,
          scale: backTo,
          transformOrigin: "50% 50%",
          ease: "elastic.out(1, 0.3)",
          onComplete: () => {
            // Game.interactionPaused = false
            onComplete()
          }
        })
      }
    })
  },

  checkForWin: () => {
    var hasWinningMatch = false
    var hasMatch = false
    var numUsedInMatch = null

    var selectedNumber = Game.DOM.selectedNumber()
    var num = parseInt($(selectedNumber).html())
    var numUsed = parseInt($(selectedNumber).attr(Game.NUM_USED_NUMBERS_ATTR))
    if(num == Game.currentCard.target) {
      hasMatch = true
      numUsedInMatch = numUsed
      hasWinningMatch = (numUsed >= Game.numCardsNeededForPlayLevel())
    }

    return {hasWinningMatch, hasMatch, numUsedInMatch}
  },

  win: () => {
    Game.interactionPaused = true

    Game.recordWin()

    Game.DOM.operationButtons().removeClass(Game.OPERATION_ACTIVE_CLASS)

    let finishUp = () => {
      Game.DOM.unselectedUnusedNumbers().addClass(Game.NUMBER_USED_CLASS)
      Game.DOM.cardSections().addClass(Game.NUMBER_USED_CLASS)
      Game.DOM.playSection().addClass("win")
      Game.DOM.hintTextCollapse().collapse("hide")
      Game.DOM.playLevelLabels().hide()

      var adsumudi = Game.DOM.winMonster()
      adsumudi.attr("transform","translate(0, 30)")
      adsumudi.show()
      gsap.to(adsumudi, {duration: 0.5, ease: "power4.out", translateY: 0});

      setTimeout(() => {
        Game.DOM.newGameButton().addClass(Game.NEW_BTN_PULSE_CLASS)
        if(Game.numWins() == 1) {
          showModal("You did it!", Game.DOM.winMessage().html())
        } else if(Game.numWins() == 3 || (((Game.numWins() - 3) % 10 == 0) && !window.gaveEmail())) {
          showModal("Enjoying Adsumudi?<br />Get 10% Off!", Game.DOM.signUpMessage().html())
          Nav.handleSmoothScrollLinks()
          $(".modal .smooth-scroll").on("click touchstart", () => { $(".modal").modal("hide") })
        }
        Game.resetHints()
        Game.gameOver = true
      }, 1000)
    }

    var unselectedUnusedNumbers = Game.DOM.unselectedUnusedNumbers()
    if(unselectedUnusedNumbers.length == 0) {
      finishUp()
    } else {
      gsap.killTweensOf(unselectedUnusedNumbers)
      gsap.to(unselectedUnusedNumbers, {
        duration: 0.5,
        scale: 0.3,
        rotate: 360,
        opacity: 0,
        transformOrigin: "50% 50%",
        onComplete: finishUp
      });
    }
  },

  numWins: () => {
    var num = Cookies.get("num-wins")
    return (num && !isNaN(num)) ? num : 0
  },

  recordWin: () => {
    var numWins = parseInt(Game.numWins())
    Cookies.set("num-wins", numWins + 1)
  },

  ariaSay: (str) => {
    Game.DOM.ariaAlert().html(str)
  }
}

window.unsetGaveEmail = () => {
  Cookies.remove("gave-email")
}

window.setGaveEmail = () => {
  Cookies.set("gave-email", "yes")
}

window.gaveEmail = () => {
  return Cookies.get("gave-email") == "yes"
}
