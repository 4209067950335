export let Nav = {
  DOM: {
    window: () => $(window),
    entireBody: () => $('html,body'),
    body: () => $('body'),
    navbar: () => $(".navbar"),
    navbarContainer: () => $(".navbar .container-fluid"),
    navbarCollapse: () => $(".navbar-collapse"),
    navbarToggler: () => $("button.navbar-toggler"),
    navbarLinks: () => $("a.nav-link"),
    anyNonNavElement: () => $("body *").not(".navbar").not(".navbar *"),
    smoothScrollLinks: () => $("a.smooth-scroll")
  },

  SCROLL_TO_OFFSET: 50,
  NAV_SCROLL_SPEED : 250,

  windowScrollTop: () => {
    return window.pageYOffset || document.documentElement.scrollTop
  },

  scrollTo: (el, onComplete) => {
    if(typeof(onComplete) === "undefined") {
      onComplete = () => {}
    }
    var elTop = el.offset().top
    var offset = Nav.SCROLL_TO_OFFSET
    var navOffset = Nav.DOM.navbar().hasClass("fixed-top") ? parseInt(Nav.DOM.navbar().css("top")) : (-1 * Nav.DOM.navbar().outerHeight())
    offset += (Nav.DOM.navbar().outerHeight() + navOffset)
    Nav.tmpDisableAnimateNavBar()
    Nav.DOM.entireBody().animate({ scrollTop: elTop - offset }, 'slow', () => {
      $(el).focus()
      onComplete()
    });
  },

  lastScrollTop: 0,

  setUpNavbar: () => {
    Nav.lastScrollTop = Nav.windowScrollTop()
    Nav.DOM.window().scroll(Nav.animateNavbar)

    if(Nav.DOM.navbarToggler().is(":visible")) {
      Nav.DOM.navbarLinks().attr("tabindex", "-1")
    }

    Nav.DOM.anyNonNavElement().focus(() => {
      Nav.DOM.navbarCollapse().collapse("hide")
    })

    Nav.DOM.navbarCollapse().on("show.bs.collapse", () => {
      Nav.DOM.navbarToggler().focus()
      Nav.DOM.navbarToggler().attr("aria-label","close mobile menu")
    })

    Nav.DOM.navbarCollapse().on("shown.bs.collapse", () => {
      Nav.DOM.navbarLinks().removeAttr("tabindex")
    })

    Nav.DOM.navbarCollapse().on("hide.bs.collapse", () => {
      Nav.DOM.navbarLinks().attr("tabindex", "-1")
      Nav.DOM.navbarToggler().attr("aria-label","open mobile menu")
    })
  },

  scrollDir: null,

  animateNavbar: () => {
    if(Nav.disableNavAnimation) return;

    var st = Nav.windowScrollTop();
    var navbarHeight = Nav.DOM.navbar().outerHeight();

    if(st > navbarHeight) {
      if(!Nav.DOM.navbar().hasClass("fixed-top")) {
        Nav.DOM.navbar().css("top", -1 * navbarHeight)
        Nav.DOM.navbar().addClass("fixed-top")
        Nav.DOM.body().css("padding-top",navbarHeight)
      }

      if (st > Nav.lastScrollTop && Nav.scrollDir != "down") {
        Nav.DOM.navbar().stop(true, false)
        Nav.DOM.navbar().animate({top: -1 * navbarHeight}, Nav.NAV_SCROLL_SPEED)
        Nav.scrollDir = "down"
      } else if (st < Nav.lastScrollTop && Nav.scrollDir != "up") {
        Nav.DOM.navbar().stop(true, false)
        Nav.DOM.navbar().animate({top: 0}, Nav.NAV_SCROLL_SPEED)
        Nav.scrollDir = "up"
      }

      if(Nav.DOM.navbarCollapse().hasClass("show")) {
        setTimeout(function() { Nav.DOM.navbarToggler().click() }, Nav.NAV_SCROLL_SPEED)
      }
    } else if(st <= 0) {
      Nav.DOM.navbar().removeClass("fixed-top")
      Nav.DOM.body().css("padding-top",0)
    }

    Nav.lastScrollTop = st;
  },

  disableNavAnimation: false,
  disableNavAnimationTimeout: null,
  tmpDisableAnimateNavBar: () => {
    Nav.disableNavAnimation = true;
    if(Nav.disableNavAnimationTimeout != null) {
      clearTimeout(Nav.disableNavAnimationTimeout);
      Nav.disableNavAnimationTimeout = null;
    }
    Nav.disableNavAnimationTimeout = setTimeout(() => {
      Nav.disableNavAnimation = false
    }, 1000)
  },

  handleSmoothScrollLinks: () => {
    Nav.DOM.smoothScrollLinks().on("click touchstart", (event) => {
      var scrollToId = $(event.target).attr("href")
      var scrollToEl = $(scrollToId)
      Nav.scrollTo(scrollToEl)
      return false;
    })
    if(window.location.hash != "") {
      var scrollToEl = $(window.location.hash)
      Nav.scrollTo(scrollToEl)
    }
  }
}
