import { Utils } from './utils';
import Fraction from "fraction.js";

export let Hints = {
  DOM: {
    window() { return $(window) },
    entireBody() { return $('html,body') },
    body() { return $('body') },
    hintForm() { return $("section.hints form")},
    cardNumberFields() { return $("section.hints input[type=number]")},
    getHintButtons() { return $("section.card-hints .hint-button-wrapper button") },
    hintText(difficulty) { return $("section.card-hints .hint-button-wrapper p.hint-text." + difficulty) }
  },

  PlayLevel: {
    EASY: "easy",
    MEDIUM: "medium",
    HARD: "hard",
    MONSTROUS: "monstrous"
  },

  currentCard: null,
  currentPlayLevel: null,
  currentHintNumbers: {},

  setCard(card) {
    Hints.currentCard = card;
    Hints.resetHints()
  },

  setPlayLevel(playLevel) {
    if(playLevel != Hints.currentPlayLevel) {
      Hints.currentPlayLevel = playLevel
      Hints.resetHints()
    }
  },

  resetHints() {
    Hints.currentHintNumbers[Hints.currentPlayLevel] = -1
  },

  currentHintNumber() {
    return Hints.currentHintNumbers[Hints.currentPlayLevel]
  },

  currentHint() {
    return Hints.currentHints()[Hints.currentHintNumber()]
  },

  nextHint() {
    if(Hints.isFinalHint()) {
      return null
    }

    Hints.currentHintNumbers[Hints.currentPlayLevel] += 1
    return Hints.currentHint()
  },

  isFirstHint() {
    return Hints.currentHintNumber() == 0
  },

  isFinalHint() {
    return Hints.currentHintNumber() == Hints.currentHints().length - 1
  },

  isFinalHintNext() {
    return Hints.currentHintNumber() == Hints.currentHints().length - 2
  },

  currentHints: () => {
    return {
      [Hints.PlayLevel.EASY]: [
        Hints.formattedTryUsingCardsHint(Hints.currentCard.easy_hint1, true),
        Hints.formattedTryUsingCardsHint(Hints.currentCard.easy_hint2),
        Hints.formattedFinalHint(Hints.currentCard.easy_hint3, false)
      ],
      [Hints.PlayLevel.MEDIUM]: [
        Hints.formattedTryUsingCardsHint(Hints.currentCard.medium_hint1),
        Hints.formattedStartWithEqHint(Hints.currentCard.medium_hint2),
        Hints.formattedFinalHint(Hints.currentCard.medium_hint3)
      ],
      [Hints.PlayLevel.HARD]: [
        Hints.formattedTryUsingCardsHint(Hints.currentCard.hard_hint1),
        Hints.formattedStartWithEqHint(Hints.currentCard.hard_hint2),
        Hints.formattedThenDoEqHint(Hints.currentCard.hard_hint3),
        Hints.formattedFinalHint(Hints.currentCard.hard_hint4)
      ],
      [Hints.PlayLevel.MONSTROUS]: [
        Hints.formattedStartWithEqHint(Hints.currentCard.monstrous_hint1),
        Hints.formattedThenDoEqHint(Hints.currentCard.monstrous_hint2),
        Hints.formattedThenDoEqHint(Hints.currentCard.monstrous_hint3),
        Hints.formattedFinalHint(Hints.currentCard.monstrous_hint4)
      ]
    }[Hints.currentPlayLevel]
  },

  formattedTryUsingCardsHint(hintText, startWith = false) {
    if(hintText == null) return ""
    var tryUsing = startWith ? "Start with" : "Try using"
    return tryUsing + " the " + Utils.listify(hintText.split(" | "))
  },

  formattedFinalHint(hintText, useThen = true) {
    if(hintText == null) return ""
    var result = useThen ? "Then do " : "Do "
    return result + Hints.formattedHintEq(hintText) + " to make Adsumudi's answer of " + Hints.evalEq(hintText)
  },

  formattedThenDoEqHint(hintText) {
    if(hintText == null) return ""
    return "Then do " + Hints.formattedHintEq(hintText) + " to make " + Hints.evalEq(hintText)
  },

  formattedStartWithEqHint(hintText) {
    if(hintText == null) return ""
    return "Start with " + Hints.formattedHintEq(hintText) + " to make " + Hints.evalEq(hintText)
  },

  formattedHintEq(eqText) {
    if(eqText == null) return null;
    if(Hints.hintEqInvolvedFractions(eqText)) {
      return eqText;
    } else {
      return eqText.replace(/\//g,"÷")
    }
  },

  hintEqInvolvedFractions(eqStr) {
    var eqParts = eqStr.split(" ")
    var n1 = eqParts[0]
    var n2 = eqParts[2]    
    return n1.match("/") != null || n2.match("/") != null
  },

  evalEq(eqStr) {
    var stdEqStr = eqStr.replace(/÷/g,"/").replace(/x/g,"*").replace(/×/g,"*").replace(/\$/g,"")
    
    if(Hints.hintEqInvolvedFractions(eqStr)) {
      var eqParts = stdEqStr.split(" ")
      var n1 = eqParts[0]
      var n2 = eqParts[2]    
      var symbol = eqParts[1]
      n1 = Fraction(n1)
      n2 = Fraction(n2)
      var res = null
      if(symbol == "+") {
        res = n1.add(n2)
      } else if(symbol == "-") {
        res = n1.sub(n2)
      } else if(symbol == "*") {
        res = n1.mul(n2)
      } else if(symbol == "/") {
        res = n1.div(n2)
      }
      if(res.d == 1) return res.toString()
      else return res.n + "/" + res.d
    } else {
      var isMoney = eqStr.match("\\$") != null
      var result = eval(stdEqStr)
      if(isMoney) {
        if(result == Math.round(result)) {
          return "$" + result;
        } else {
          return "$" + parseFloat(result).toFixed(2)
        }
      } else {
        return result;
      }
    }
  },

  handleGetHints() {
    if(Hints.DOM.getHintButtons().length == 0) return 
    let touchEvent = Utils.isMobile() ? "touchend" : "click"
    Hints.DOM.getHintButtons().on(touchEvent, Hints.getHintButtonClicked)
    Hints.setCard(CARD_FOR_HINTS)
  },

  getHintButtonClicked(event) {
    var button = $(event.target)
    var difficulty = button.attr("adsumudi-data-difficulty")
    var hintText = Hints.DOM.hintText(difficulty)
    Hints.setPlayLevel(difficulty)
    var hint = Hints.nextHint()
    if(Hints.isFirstHint()) {
      hintText.html(hint)
    } else {
      hintText.html(hintText.html() + "<br />" + hint)
    }
    if(Hints.isFinalHintNext()) {
      button.html("SHOW SOLUTION")
    } else if(Hints.isFinalHint()) {
      button.hide()
    } else {
      button.html("NEXT HINT")
    }
  },

  handleFormSubmission() {
    Hints.DOM.hintForm().on("submit", Hints.checkForErrors)
    Hints.DOM.cardNumberFields().on("keyup change", Hints.formatCardNumberFields)
  },

  checkForErrors(event) {
    var hasError = false;
    $.each(Hints.DOM.cardNumberFields(), function(i, field) {
      if($(field).val() == "") {
        $(field).addClass("error")
        if(!hasError) {
          $(field).focus()
        }
        hasError = true
      }
    })
    if(hasError) {
      event.stopPropagation()
      event.preventDefault();
    }
    return !hasError
  },

  formatCardNumberFields(event) {
    var field = $(event.target)
    if(field.val().match(/[^0-9]/)) {
      field.val(field.val().replace(/[^0-9]/g, "").trim())
    }
    if(parseInt(field.val()) == 0) {
      field.val(0)
    } else {
      field.val(field.val().replace(/^0+/g, "").trim())
    }
    if(parseInt(field.val()) > 99) {
      field.val(field.val().substring(0,2))
    }
    if(field.val() != "") {
      $(field).removeClass("error")
    }
  }
}
